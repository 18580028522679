<template>
  <div>
    <c-search-box @enter="getSearchList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="edit" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept
            type="search"
            label="관리부서"
            name="deptCd"
            :isFirstValue="true"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="LBLUSEFLAG"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <!-- 공정 목록 -->
        <c-tree-table
          ref="processTable"
          title="LBL0001702"
          parentProperty="upProcessCd"
          customID="processCd"
          :columns="grid.columns"
          :data="grid.data"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :expandAll="true"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <!-- 검색 -->
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getSearchList" />
            </q-btn-group>
          </template>
        </c-tree-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <q-form ref="editForm">
          <!-- 공정상세 -->
          <c-card :title="title" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- 신규 공정 -->
                <c-btn v-if="editable" label="신규 제조공정" icon="add" @btnClicked="addParent" />
                <!-- 신규 단위공정 -->
                <c-btn v-if="editable && this.data.processLevelCd == '10' && selectedProcessCd" label="신규 공정절차(작업절차)" icon="add" @btnClicked="addChild" />
                <!-- 삭제 -->
                <c-btn v-if="editable && dataeditable && selectedProcessCd" label="LBLREMOVE" icon="remove" @btnClicked="removeProcess" />
                <!-- 저장 -->
                <c-btn
                  v-if="editable && dataeditable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail" v-if="data.processLevelCd != '20'">
              <div class="col-8">
                <!-- 공정명 -->
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="제조공정명"
                  name="processName"
                  v-model="data.processName">
                </c-text>
              </div>
              <div class="col-4">
                <!-- 관리부서 -->
                <c-dept label="LBL0001721" :required="true" :editable="editable && dataeditable" :plantCd="data.plantCd" name="managementDepts" v-model="data.managementDepts" />
              </div>
              <div class="col-4">
                <!-- 사업장 -->
                <c-plant
                  :required="true"
                  :editable="editable && dataeditable"
                  label="LBLPLANT"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-4">
                <!-- 정렬순서 -->
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="LBLSORTORDER"
                  name="orderNo"
                  v-model="data.orderNo">
                </c-text>
              </div>
              <div class="col-4">
                <!-- 사용여부 -->
                <c-checkbox
                  :editable="editable && dataeditable"
                  :isFlag="true"
                  label="LBLUSEFLAG"
                  name="useFlag"
                  v-model="data.useFlag"
                />
              </div>
              <!-- <div class="col-12">
                <c-textarea
                  :counter="true"
                  :editable="editable && dataeditable"
                  label="제조공정 설명"
                  name="processDesc"
                  :rows="2"
                  v-model="data.processDesc">
                </c-textarea>
              </div> -->
              <div class="col-6">
                <c-table
                  ref="machineGrid"
                  title="유해위험기계기구"
                  :columns="machineGrid.columns"
                  gridHeight="250px"
                  :data="data.macinerys"
                  :columnSetting="false"
                  :isFullScreen="false"
                  :usePaging="false"
                  :expandAll="false"
                  :filtering="false"
                  :isExcelDown="false"
                  :hideBottom="true"
                >
                </c-table>
              </div>
              <div class="col-6">
                <c-table
                  ref="chemGrid"
                  title="유해화학물질"
                  :columns="chemGrid.columns"
                  gridHeight="250px"
                  :data="data.chemlist"
                  :columnSetting="false"
                  :isFullScreen="false"
                  :usePaging="false"
                  :expandAll="false"
                  :filtering="false"
                  :isExcelDown="false"
                  :hideBottom="true"
                  selection="multiple"
                  rowKey="mdmChemId"
                >
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn label="LBLADD" v-if="editable && dataeditable" icon="add" @btnClicked="addrow" />
                      <c-btn label="LBLREMOVE" v-if="editable && dataeditable" icon="remove" @btnClicked="removeRow" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
              <div class="col-12" style="margin-top:20px;">
                <!-- 공정 사진 -->
                <c-upload
                  :attachInfo="attachInfoL1Photo"
                  :editable="editable && dataeditable"
                  label="제조공정 사진">
                </c-upload>
              </div>
            </template>
            <template slot="card-detail" v-if="data.processLevelCd == '20'">
              <div class="col-9">
                <!-- 단위공정명 -->
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="공정절차(작업절차) 명"
                  name="processName"
                  v-model="data.processName">
                </c-text>
              </div>
              <div class="col-3">
                <!-- 정렬순서 -->
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="LBLSORTORDER"
                  name="orderNo"
                  v-model="data.orderNo">
                </c-text>
              </div>
              <div class="col-5">
                <!-- 상위공정 -->
                <c-text
                  :editable="false"
                  label="제조공정"
                  name="upProcessName"
                  v-model="data.upProcessName">
                </c-text>
              </div>
              <div class="col-4">
                <!-- 관리부서 -->
                <c-dept label="LBL0001721" :required="true" :editable="editable && dataeditable" :plantCd="data.plantCd" name="managementDepts" v-model="data.managementDepts" />
              </div>
              <div class="col-3">
                <!-- 사용여부 -->
                <c-checkbox
                  :editable="editable && dataeditable"
                  :isFlag="true"
                  label="LBLUSEFLAG"
                  name="useFlag"
                  v-model="data.useFlag"
                />
              </div>
              <div class="col-12">
                <c-textarea
                  :counter="true"
                  :editable="editable && dataeditable"
                  label="공정절차(작업절차) 설명"
                  name="processDesc"
                  :rows="2"
                  v-model="data.processDesc">
                </c-textarea>
              </div>
              <div class="col-12">
                <c-upload
                  :attachInfo="attachInfoL2Photo"
                  :editable="editable && dataeditable"
                  label="공정절차(작업절차) 사진">
                </c-upload>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import transactionConfig from '@/js/transactionConfig';
import selectConfig from '@/js/selectConfig';
export default {
  name: 'process-manage',
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
        deptCd: '',
      },
      machineGrid: {
        columns: [
          {
            name: 'machineryClassificationName',
            field: 'machineryClassificationName',
            label: '기계분류',
            align: 'center',
            sortable: true,
          },
          {
            name: 'machineryName',
            field: 'machineryName',
            label: '기계명',
            align: 'center',
            sortable: true,
          },
          {
            name: 'cnt',
            field: 'cnt',
            label: '수량',
            align: 'right',
            sortable: true,
          },
        ],
      },
      chemGrid: {
        columns: [
          {
            name: 'chemNm',
            field: 'chemNm',
            label: '화학물질명',
            align: 'center',
            style: 'width:60%',
            sortable: true,
          },
          {
            name: 'useQty',
            field: 'useQty',
            label: '취급량',
            type: 'number',
            style: 'width:20%',
            sortable: true,
          },
          {
            name: 'useTime',
            field: 'useTime',
            label: '취급시간',
            type: 'number',
            style: 'width:20%',
            sortable: true,
          },
        ],
      },
      grid: {
        columns: [
          {
            name: 'processName',
            field: 'processName',
            // 공정명
            label: '제조공정 > 공정절차(작업절차)',
            align: 'left',
            sortable: false,
          },
          {
            name: 'managementDeptsName',
            field: 'managementDeptsName',
            label: '부서',
            align: 'center',
            style: 'width: 160px',
            sortable: true,
          },
          {
            name: 'orderNo',
            field: 'orderNo',
            // 순번
            label: 'LBLSORTORDER',
            align: 'center',
            style: 'width: 70px',
            sortable: false,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            // 사용여부
            label: 'LBLUSEFLAG',
            align: 'center',
            style: 'width: 70px',
            sortable: false,
          },
        ],
        data: [],
      },
      listUrl: '',
      detailUrl: '',
      checkUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      deleteChemUrl: '',
      saveable: false,
      deleteable: false,
      editable: true,
      dataeditable: false,
      updateMode: false,
      isSave: false,
      saveUrl: transactionConfig.mdm.process.insert.url,
      saveType: 'POST',
      selectedProcessCd: '',
      selectedProcess: null,
      attachInfoL1Photo: {
        isSubmit: '',
        taskClassCd: 'PROCESS_UP',
        taskKey: '',
      },
      attachInfoL2Photo: {
        isSubmit: '',
        taskClassCd: 'PROCESS_UNIT_PHOTO',
        taskKey: '',
      },
      attachInfoL1Hazard: {
        isSubmit: '',
        taskClassCd: 'PROCESS_UNIT_HAZARD',
        taskKey: '',
      },
      psmYnItems: [
        { code: 'Y', codeName: '대상' },
        { code: 'N', codeName: '해당없음' },
      ],
      data: {
        plantCd: null,
        processCd: '',
        processNo: '',
        processName: '',
        upProcessCd: '',
        upProcessName: '',
        processLevelCd: '',
        processDesc: '',
        managementDepts: '',
        managementDeptsName: '',
        orderNo: '',
        mainMaterial: '',
        workerCnt: '',
        productName: '',
        useFlag: 'Y',
        psmFlag: 'N',
        workCycleCd: null,
        workingTime: null,
        temperature: '',
        macinerys: [],
        chemlist: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    title() {
      return this.data.processLevelCd == '20' ? '공정절차(작업절차) 상세' : '제조공정 상세';
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.process.list.url;
      this.detailUrl = selectConfig.mdm.process.get.url;
      this.checkUrl = selectConfig.mdm.process.check.url;
      this.insertUrl = transactionConfig.mdm.process.insert.url;
      this.updateUrl = transactionConfig.mdm.process.update.url;
      this.deleteUrl = transactionConfig.mdm.process.delete.url;
      this.deleteChemUrl = transactionConfig.mdm.process.chemdelete.url;
      this.getList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getSearchList() {
      this.getList();
      this.reset();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
        if (this.selectedProcessCd != '') {
          this.$refs["processDetail"].rowClick({ processCd: this.selectedProcessCd });
        } else {
          if (this.$refs["processDetail"] !== undefined) {
            this.$refs["processDetail"].reset();
          }
        }
      },);
    },
    rowClick(row) {
      // 상세조회
      this.selectedProcess = row;
      this.selectedProcessCd = row.processCd;
      // 상세조회
      this.saveable = true;
      this.deleteable = true;
      this.rowNotSelected = false;
      this.updateMode = true;
      this.$http.url = this.$format(this.detailUrl, this.selectedProcessCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.$set(this.attachInfoL1Photo, 'taskKey', row.processCd)
        this.$set(this.attachInfoL1Hazard, 'taskKey', row.processCd);
        this.$set(this.attachInfoL2Photo, 'taskKey', row.processCd);
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
        this.dataeditable = true;
      },
      () => {
      });
    },
    reset() {
      this.rowRemoveSelect();
      this.selectedProcessCd = '';
      this.selectedProcess = null;
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.dataeditable = false;
      this.data = {
        plantCd: null,
        processCd: '',
        processNo: '',
        processName: '',
        upProcessCd: '',
        upProcessName: '',
        processLevelCd: '',
        processDesc: '',
        managementDepts: '',
        orderNo: '',
        mainMaterial: '',
        workerCnt: '',
        productName: '',
        useFlag: 'Y',
        psmFlag: 'N',
        workCycleCd: null,
        workingTime: null,
        temperature: '',
        macinerys: [],
        chemlist: [],
      };
      this.$set(this.attachInfoL1Photo, 'taskKey', '');
      this.$set(this.attachInfoL1Hazard, 'taskKey', '');
      this.$set(this.attachInfoL2Photo, 'taskKey', '');
    },
    saveData() {
      this.$refs['editForm'].validate().then(_result => {
        // 중복체크
        if (_result) {
          this.$http.url = this.$format(this.checkUrl, this.data.processNo);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            if (this.updateMode || _result.data === 0) {
              window.getApp.$emit('CONFIRM', {
                title: 'LBLCONFIRM',
                message: 'MSGSAVE', // 저장하시겠습니까?
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.data.regUserId = this.$store.getters.user.userId
                  this.data.chgUserId = this.$store.getters.user.userId
                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: 'LBLGUIDE', // 안내
                message:
                '동일한 공정관리번호가 존재합니다.', // 동일한 공정관리번호가 존재합니다.
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    removeProcess() {
      let msg = 'MSGREMOVE';
      if (this.selectedProcess.processLevelCd == '10') {
        msg = '상위공정 삭제 시 하위공정도 모두 삭제됩니다.\r\n삭제하시겠습니까?';
      }
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: msg, // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.data.processCd);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.getSearchList();
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(_result) {
      let _processCd = _result.data;
      if (this.saveType == 'POST') {
        this.$set(this.attachInfoL1Photo, 'taskKey', _processCd)
        this.$set(this.attachInfoL1Photo, 'isSubmit', uid())
        this.$set(this.attachInfoL1Hazard, 'taskKey', _processCd);
        this.$set(this.attachInfoL1Hazard, 'isSubmit', uid())
        this.$set(this.attachInfoL2Photo, 'taskKey', _processCd)
        this.$set(this.attachInfoL2Photo, 'isSubmit', uid())
      }
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getSearchList();
    },
    addParent() {
      this.attachable = true;
      this.saveable = true;
      this.deleteable = false;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.data = {
        plantCd: this.searchParam.plantCd,
        processCd: '',
        processNo: '',
        processName: '',
        upProcessCd: '',
        upProcessName: '',
        processLevelCd: '10',
        processDesc: '',
        managementDepts: '',
        orderNo: '',
        mainMaterial: '',
        workerCnt: '',
        productName: '',
        useFlag: 'Y',
        psmFlag: 'N',
        workCycleCd: null,
        workingTime: null,
        temperature: '',
        macinerys: [],
        chemlist: [],
      };
      this.$set(this.attachInfoL1Photo, 'taskKey', '')
      this.$set(this.attachInfoL1Hazard, 'taskKey', '');
    },
    addChild() {
      this.attachable = true;
      this.saveable = true;
      this.deleteable = false;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.data = {
        plantCd: this.selectedProcess.plantCd,
        processCd: '',
        processNo: '',
        processName: '',
        upProcessCd: this.selectedProcess.processCd,
        upProcessName: this.selectedProcess.processName,
        processLevelCd: '20',
        processDesc: '',
        managementDepts: '',
        orderNo: '',
        mainMaterial: '',
        workerCnt: '',
        productName: '',
        useFlag: 'Y',
        psmFlag: 'N',
        workCycleCd: null,
        workingTime: null,
        temperature: '',
        macinerys: [],
        chemlist: [],
      };
      this.$set(this.attachInfoL2Photo, 'taskKey', '');
    },
    addrow() {
      this.popupOptions.target = () => import(`${'@/pages/mdm/mam/chem/chemBasePop.vue'}`);
      this.popupOptions.title = '화학물질';
      this.popupOptions.width = '60%';
      this.popupOptions.param = {
        type: 'multiple',
        isDetail: true,
      }
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeChemBasePopup;
    },
    closeChemBasePopup(_data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if(_data && _data.length > 0) {
        this.$_.forEach(_data, item => {
          if (this.$_.findIndex(this.data.chemlist, { mdmChemId: item.mdmChemId }) === -1) {
            this.data.chemlist.push({
              processCd: this.data.processCd,
              mdmChemId: item.mdmChemId,
              chemNm: item.chemNmEn,
              useQty: 0,
              useTime: 0,
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
            })
          }
        })
      }
    },
    removeRow() {
      let selectData = this.$refs['chemGrid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteChemUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.data.chemlist = this.$_.reject(this.data.chemlist, item);
              })
              this.$refs['chemGrid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
